:root {
    --bootstrap-secondary: #6c757d;
    --light-grey: #ced4da;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-print-color-adjust: exact !important; /* enable background graphics in Chrome, Safari, Edge */
    color-adjust: exact !important; /* enable background graphics in Firefox */
}

body, #root {
    position: relative;
    color: var(--bootstrap-secondary);
    border-color: var(--light-grey);
    border-radius: 5px;
    background-color: #fff;
}

h1 {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}

/*Login button*/
.login-container {
    position: fixed;
    top: 30%;
}

.login-logo-container {
    position: fixed;
    top: 20%;
}

.dropdown-menu {
    min-width: 20rem;
    padding: 0.5rem;
}

.button-no-borders, .button-no-borders:focus {
    border: 0 !important;
    box-shadow: none;
}

.button-no-borders > svg {
    width: 1.5rem;
    height: 1.5rem;
}

.tooltip-inner {
    background-color: #fff;
    color: var(--bootstrap-secondary);
    border: 1px solid var(--bootstrap-secondary);
}

#logout-tooltip > .tooltip-arrow, #password-tooltip > .tooltip-arrow {
    border-top: 5px solid var(--bootstrap-secondary);
}

/* custom slider start*/
.switch {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    transition: 0.4s;
    border-radius: 50%;
}

.slider:after {
    content: attr(data-default);
    position: absolute;
    right: 10px;
    bottom: 5px;
    font-weight: 300;
    color: white;
    transition: 0.4s;
}

input:checked + .slider:after {
    content: attr(data-checked);
    left: 10px;
}

input:checked + .slider {
    background-color: #0e850e;
}

input:checked + .slider:before {
    transform: translateX(46px);
}

.label {
    display: block;
    width: 200%;
    margin-left: -100%;
}

.label:before, label:after {
    display: block;
    float: left;
    width: 50%;
    color: red;
}

.label:before {
    content: 'Yes';
}

.label:after {
    content: 'No';
}

/* custom slider end*/

/*navbar*/
.navbar-header {
    position: absolute;
    left: 100px;
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
}

.navbar-padding {
    padding: 0 5px;
}

button[data-type='navbar-icon'] {
    padding: 0.375rem;
}

.navbar-button {
    display: flex;
    align-items: center;
}

span.input-group-text {
    color: var(--bootstrap-secondary);
    border-color: var(--light-grey);
    background-color: transparent;
}

button.btn-outline-secondary, .modal-content, .description-textarea {
    border-color: var(--light-grey);
}

.form-control {
    color: var(--bootstrap-secondary);
}

.form-control:focus {
    border-color: var(--light-grey);
    box-shadow: none;
}

.form-text {
    font-size: unset;
}

div.input-group .btn, div.input-group .form-control.is-invalid, form.was-validated div.input-group .form-control:invalid, div.input-group .form-control.is-valid, form.was-validated div.input-group .form-control:valid {
    z-index: 0;
}

/*custom table*/
.reports-table .table {
    width: auto;
}

.custom-table {
    text-align: center;
    vertical-align: middle;
    font-size: 0.65rem;
    width: auto;
    height: 30px;
}

.custom-table .button-no-borders > svg {
    width: 1rem;
    height: 1rem;
}

.custom-table button {
    height: 1rem;
    display: flex;
    margin: auto;
}

.clickable-row:hover {
    cursor: pointer;
}

.custom-table th, .custom-table td {
    white-space: break-spaces;
    word-wrap: break-word;
    padding: 5px;
}

ul.pagination {
    margin-bottom: 0;
}

.page-link:focus, .btn-secondary:focus, .btn-light:focus {
    box-shadow: none;
}

.btn-light, .btn-light:focus, .btn-light:hover {
    color: var(--bootstrap-secondary);
    border: 1px solid var(--light-grey);
}

.react-bootstrap-table-pagination {
    position: absolute;
    bottom: 0px;
    width: 97%;
    background-color: #fff;
    margin-left: 0;
    margin-right: 0;
    height: 35px;
}

.react-bootstrap-table-pagination > * {
    padding-left: 0;
}

.react-bootstrap-table-pagination button, .page-link {
    font-size: 1rem;
    padding: 2px 8px;
}

.react-bootstrap-table-pagination-list {
    padding-right: 0;
}

.dropdown-item:active {
    background-color: var(--light-grey);
}

.dropdown-toggle::after, .dropup .dropdown-toggle::after {
    display: none;
}

.header-top {
    white-space: break-spaces;
}

tr.danger {
    animation: pulse-border-danger 1s 5;
    background-color: indianred;
}

td.danger {
    animation: pulse-border-danger 1s 5;
    background-color: indianred;
}

/*hide label of filter input*/
.filter-label span {
    display: none;
}

.filter-label .form-control {
    font-size: 0.7rem;
    width: auto;
}

@keyframes pulse-border-danger {
    from, to {
        box-shadow: 0 0 0 0 #212529;
    }
    50% {
        box-shadow: 0 0 0 4px red;
    }
}

tr.attention {
    animation: pulse-border-attention 1s 5;
    background-color: #f6fc9d;
}

td.attention {
    animation: pulse-border-attention 1s 5;
    background-color: #f6fc9d;
}

.report-preview-modal {
    max-width: 90%;
}


.report-preview-modal .no_print {
    display: none !important;
}

@keyframes pulse-border-attention {
    from, to {
        box-shadow: 0 0 0 0 #212529;
    }
    50% {
        box-shadow: 0 0 0 4px #e9f542;
    }
}

.close ::before {
    content: 'X';
    visibility: visible;
    color: #073407;
    font-weight: normal;
}

.sr-only::before {
    visibility: hidden;
}

.close {
    visibility: hidden;
    position: absolute;
    right: 32px;
    top: 10px;
    width: 20px;
    height: 20px;
    background-color: rgba(247, 12, 12, 0.5);
}

.card-standout {
    box-shadow: 10px 10px 30px 15px grey;
}

.report-panel, .modal-content {
    box-shadow: 0 0 5px 0 grey;
}

.centered-report-panel {
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-data {
    min-height: 100%;
    display: flex;
    align-items: center;
}

.card .border {
    /*border: 1px solid #6c757d;*/
    border-color: #6c757d !important;
    border-radius: 3px;
}

.react-bootstrap-table {
    clear: both;
    margin-bottom: 35px;
}

.table {
    margin-bottom: 0;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: '';
}

.hidden-caret:after {
    display: none;
}

.card-body {
    padding: 0.5rem 0.5rem;
}

.expanded-table {
    position: fixed;
    top: 0;
    left: 10px;
    height: 100vh !important;
    width: 99vw;
    z-index: 1050;
}

.overlay-border, td.overlay-border {
    border: 2px dashed #38a638 !important;
    border-radius: 5px !important;
    position: relative;
}

.overlay-faded {
    opacity: 0.2;
}

.overlay-text {
    border: 2px solid #38a638;
    border-radius: 5px;
    background-color: #efeeee;
    text-align: center;
}

.overlay-border .overlay-text {
    position: absolute;
    left: 35%;
    top: 25%;
    width: 30%;
}

.multiline-table tspan {
    border: 5px solid green;
}

.bordered {
    border: 2px solid var(--bootstrap-secondary);
}

.single-line-chart {
    width: 100%;
    height: 100px;
}

.multiline-chart {
    width: 100%;
    height: 380px;
}

.gage-chart {
    width: 100%;
    height: 250px;
}

.piechart, .columnchart {
    width: 100%;
    height: 150px;
}

.connection-chart {
    width: 100%;
    height: 360px;
}

.sunburst-chart {
    width: 100%;
    height: 320px;
}

.to-print {
    display: none;
}

.small-text {
    font-size: 0.7rem;
}

.single-line-chart div {
    width: 18% !important;
}

.single-line-chart svg, .gage-chart svg, .multiline-chart svg, .piechart svg, .columnchart svg, .sunburst-chart svg {
    position: absolute;
}

.piechart div, .columnchart div {
    width: 24% !important;
    height: 65% !important;
}

.sunburst-chart div {
    width: 10% !important;
    height: 30% !important;
}

/*.gage-chart div {
    width: 25% !important;
}*/

.multiline-chart div {
    width: 11% !important;
    height: 25% !important;
}

/*
.gage-chart svg {
    height: auto !important;
}
*/

svg[data-single='monitoring'] {
    width: 70% !important;
}

.carrier-header {
    color: #fff;
    font-weight: 500;
    font-size: 1.5rem;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.carrier-status {
    font-size: 2rem;
}

.chart-header {
    font-size: 1rem;
}

.card-height {
    min-height: 150px;
}

.card-header-height {
    min-height: 60px;
}

.card-body-height {
    min-height: 100px;
}

.number-font {
    font-weight: bold;
    font-size: 2rem;
}

.bottom-text {
    height: 20px;
    display: flex;
    align-items: flex-end;
    font-size: 0.7rem;
    line-height: 1;
}

.to-print-hidden {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    visibility: hidden;
}

.contact-card {
    border: 2px solid var(--light-grey);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    height: 95px;
    min-width: 180px;
}

.contact-card:hover {
    cursor: pointer;
}

.contact-info {
    height: auto;
    width: 400px;
    text-align: center;
    overflow: auto;
}

.contact-info:hover {
    cursor: unset;
}

.contact-info p {
    margin: 0;
}

.upload-area {
    border: 2px dashed var(--light-grey);
    border-radius: 4px;
    padding: 15px;
    height: 150px;
    width: 100%
}

.navbar {
    justify-content: right;
}

.home-button {
    position: absolute;
    left: 0;
}

.multiselect {
    color: var(--bootstrap-secondary);
    width: 100%;
    border-color: var(--light-grey);
}

.dropdown {
    width: 100%;
}

#reports-navigation .dropdown, .ticket-field .dropdown {
    height: 100%;
}

.dropdown-item, .dropdown-menu, .btn-light {
    color: var(--bootstrap-secondary);
}

.form-check-input:checked {
    background-color: var(--bootstrap-secondary);
    border-color: var(--bootstrap-secondary);
}

.report-name {
    font-weight: 400;
    font-size: 2.5rem;
}

.card-value {
    font-size: 1.5rem;
}

.card-name {
    font-size: 1rem;
}

.ticket-view-label {
    width: 100%;
    text-align: center;
}

.ticket-create-label {
    width: 100%;
}

.ticket-create-dropdown {
    width: 100%;
}

.ticket-field {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px 0;
}

.ticket-dropdown {
    width: 100%;
    height: 100%;
    min-height: 40px;
}

.ticket-modal .modal-body {
    max-height: 82vh;
    overflow-y: auto;
}

.description-textarea {
    width: 100%;
    min-height: 2rem;
    max-height: 15rem;
    overflow: auto;
    border: 1px solid var(--light-grey);
    border-radius: 5px;
    padding: 0.375rem 0.75rem;
}

.description-textarea[readOnly], .ticket-field .dropdown button:disabled, .ticket-field .dropdown button.disabled {
    background-color: #e9ecef;
    opacity: 1;
}

.description-textarea table {
    border: 1px solid black
}

.description-textarea table td {
    border: 1px solid black;
    padding: 5px
}

.comments-container {
    max-height: 500px;
    overflow-y: auto;
}

.loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 90vh;
    width: 90vh;
}

.loading-spinner {
    height: 50%;
    width: 50%;
    animation: 1.3s linear infinite spinner-border;
}

.flex-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fileContainer {
    max-height:120px;
    overflow-y: scroll;
}

.rbc-time-header-content {
    max-height: 165px;
    overflow-y: scroll;
}

.campus-optx-header-medium {
    display: block;
} 

.campus-optx-header-small {
    padding-top: 15px;
    display: none;
}

/*Bootstrap xl breakpoint*/
@media screen and (max-width: 1199px) {
    .card-value {
        font-size: 1rem;
    }

    .card-name {
        font-size: 0.6rem;
    }

    .btn {
        font-size: 0.7rem;
    }

    .navbar-header {
        font-size: 1.3rem;
        left: 70px;
    }
}

/*Bootstrap md breakpoint*/
@media screen and (max-width: 991px), (max-device-width: 991px) {


    .campus-optx-header-medium {
        display: none;
    } 

    .campus-optx-header-small {
        display: block;
    }

    .mb-3 {
        margin-bottom: 0 !important;
    }

    .custom-table {
        font-size: 0.4rem;
    }

    svg[data-single='monitoring'] {
        width: 35% !important;
    }

    .modal-dialog {
        max-width: 90vw;
    }
}

@media screen and (max-device-width: 991px) and (orientation: portrait) {
    .navbar {
        justify-content: start;
    }

    .logout {
        position: absolute;
        top: 5px;
        right: 0;
    }

    .info {
        position: absolute;
        top: 5px;
        right: 35px;
    }

    .schedule {
        position: absolute;
        top: 5px;
        right: 70px;
    }

    .report-picker {
        padding-right: 0;
    }

    .print {
        padding-left: 0;
    }

    .custom-table {
        font-size: 0.35rem;
    }
}

/*Small mobile screen*/
@media screen and (max-width: 400px), (max-device-width: 400px) {
    .navbar-header {
        position: relative;
        left: 0;
        font-size: 0.9rem;
    }

    .navbar-button {
        font-size: 0.6rem;
    }

    .button-no-borders > svg {
        width: 1.1rem;
        height: 1.1rem;
    }

    .table-height {
        height: 300px !important;
    }
}


/*Print settings*/
@media print {
    .to-print {
        display: block;
    }

    .to-print-hidden {
        position: static;
        z-index: 1;
    }

    .not-to-print {
        display: none;
    }

    .pagebreak {
        page-break-before: always;
    }

    .report-page {
        page-break-after: always;
    }

    .print-height-graph {
        height: 250px !important;
    }

    .print-height {
        height: 170px;
    }

    .print-height-medium {
        height: 100px;
    }

    .carrier-header, .carrier-status {
        font-size: 1rem;
    }

    .chart-header {
        font-size: 0.6rem;
    }

    .card-height {
        min-height: auto;
    }

    .card-header {
        height: 30px;
    }

    .card-header-height {
        min-height: 40px;
    }

    .card-body-height {
        min-height: auto;
        height: 30px;
    }

    .number-font {
        font-size: 1.5rem;
    }

    .bottom-text {
        font-size: 0.6rem;
    }

    .piechart {
        height: 100px;
    }

    .columnchart {
        height: 115px;
    }

    .sunburst-chart {
        height: 150px;
    }

    .custom-table-card {
        height: 100px;
    }

    .card-value {
        font-size: 0.8rem;
    }

    .card-name {
        font-size: 0.6rem;
    }
}
